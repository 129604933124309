@tailwind base;
@tailwind components;
@tailwind utilities;

.filter-input {
    @apply w-4;
    @apply h-4;
    @apply dark:text-violet-700;
    @apply dark:bg-indigo-800;
    @apply border-indigo-800;
    @apply rounded;
    @apply focus:ring-violet-500;
    @apply shadow;
}

.structure-card {
    @apply relative;
    @apply flex;
    @apply items-center;
    @apply lg:justify-center;
    @apply lg:text-center;
    @apply p-6;
    @apply font-semibold;
    @apply text-white;
    @apply rounded-lg;
    @apply shadow-md;
    @apply shadow-indigo-300;
    @apply dark:shadow-indigo-900;
    @apply bg-violet-700;
    @apply dark:bg-indigo-800;
}

.logo-home {
    @apply drop-shadow-md;
}

.logo-small {
    @apply drop-shadow;
}

.logo-small .st0,
.logo-home .st0 {
    @apply fill-white;
    @apply dark:fill-gray-100;
}

.logo-small .st1,
.logo-home .st1 {
    @apply fill-purple-400;
    @apply dark:fill-purple-500;
}

.hideAnno .a9s-annotationlayer,
.hideAnno .r6o-editor {
    display: none;
}
